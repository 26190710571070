import React, { Suspense, useEffect, useContext, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import NotFound from '../Ui/404';
import { Button, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import Login from '../../components/Login/Login';
import { ToastProvider } from 'react-toast-notifications';
import ViewDashboard from '../../components/appelte/ViewDashboard';
import ViewNavBar from '../../components/appelte/ViewNavBar';
import AuthContext from '../../context/authContext';
import { useTranslation } from 'react-i18next';
import { getDateTimeE } from '../../myfunctions/myfunctions';
import MyTimerLive from '../Ui/myTimerLive/MyTimerLive';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Content() {
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (authContext.showModalError && authContext.showModalError.open) {
            setOpen(true)
        }

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [authContext.showModalError]);// eslint-disable-line react-hooks/exhaustive-deps


    function closeModal() {
        setOpen(false)
        authContext.changeModalError(null)
    }
    return (
        <><ToastProvider autoDismiss autoDismissTimeout={4000}>{
            <Suspense fallback={<p>Ładowanie</p>}>
                <Modal
                    basic
                    open={open}
                    size='tiny'
                    dimmer='blurring'
                >

                    <Modal.Content>

                        <Segment textAlign='center' >
                            <Header as='h4' icon>
                                <Icon name='warning circle' color='red' />
                                {t("strona_bledu.temat_blad1")}
                                <Header.Subheader>
                                    {authContext && authContext.showModalError && authContext.showModalError.data ? authContext.showModalError.data : t("strona_bledu.blad1")}
                                </Header.Subheader>
                            </Header>
                            <br />
                            <Button style={{ width: 300 }} onClick={() => closeModal()}> {t("strona_bledu.btn_blad1")}</Button>

                            <div style={{ marginTop: 20 }}>
                                <div style={{ float: 'left', fontSize: '10px', paddingLeft: 2, lineHeight: '10px', textAlign: 'right', color: '#bababa', marginBottom: 2 }}>
                                    {authContext && authContext.userName ? <><Icon name="mail" /> {authContext.userName.email} </> : ""}
                                </div>
                                <div style={{ float: 'right', fontSize: '10px', paddingRight: 0, lineHeight: '10px', textAlign: 'right', color: '#bababa', marginBottom: 2 }}>
                                    <span><Icon name="time" /><MyTimerLive /> </span>
                                    <Icon name="calendar" />{getDateTimeE("DATE", new Date())}

                                </div>
                            </div>
                        </Segment>

                    </Modal.Content>

                </Modal>
                <Switch>

                    <Route exact path="/dashboard">

                        <>
                            {auth ? <div style={{ zoom: width > 1000 ? 1 : 0.5 }}>
                                <ViewNavBar />
                                <ViewDashboard />
                            </div> : <Redirect to="/login" />} </></Route>

                    <Route path="/login" >{auth ? <Redirect to="/dashboard" /> : <Login />} </Route>
                    <Route exact path="/">{auth ? <Redirect to="/dashboard" /> : <Redirect to="/login" />} </Route>
                    <Route component={NotFound} />


                </Switch>
            </Suspense>


        }</ToastProvider></>
    )
}

export default Content;