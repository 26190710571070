import React, { useContext, useEffect, useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
//import useWindowDimensions from '../../hooks/useWindowDimensions';

import { Button, Icon, Label, Popup, Table, TableHeaderCell } from 'semantic-ui-react';
import { getDateTimeE, loadingShow } from '../../myfunctions/myfunctions';
import MyTimerLive from '../../layout/Ui/myTimerLive/MyTimerLive';
import AuthContext from '../../context/authContext';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../languageSelect';
import packageJson from '../../../package.json';
import useWindowDimensions from '../../hooks/useWindowDimensions';


function ViewDashboard() {
    const { addToast } = useToasts();
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);
    const [isloading, setLoading] = useState(true);
    const { t } = useTranslation();

    const [dataSort, setdataSort] = useState({ column: null, data: [], direction: null })
    const [dataInfo, setdataInfo] = useState({ countFvPaid: 0, countFvNotPaid: 0, })
    const ref = useRef(null)
    const [loadinginvoice, setloadinginvoice] = useState(0)
    const [loadinginvoiceduplicate, setloadinginvoiceduplicate] = useState(0)
    const { height } = useWindowDimensions();


    useEffect(() => {

        getData()

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, []);// eslint-disable-line react-hooks/exhaustive-deps




    function getData() {
        setLoading(true);

        ConnectWS("POST", "/fv/getFVUser", authContext.token, {}, function (response, logout) {

            if (logout === 1) {
                addToast(t("strona_bledu.wylogowanie"), { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);

            } else if (Number(response.status) === 200) {

                setdataSort({ column: null, data: response.data, direction: null });
                let arrtmp = [];
                arrtmp.length = 0;

                let countFvPaid = 0;
                let countFvNotPaid = 0;
                if (response.data.length > 0) {
                    response.data.forEach((data, i) => {
                        if (data.paid_dt === null) {
                            countFvNotPaid++;
                        } else {
                            countFvPaid++
                        }
                    })
                }
                setdataInfo({ countFvPaid: countFvPaid, countFvNotPaid: countFvNotPaid })

            } else {
                authContext.changeModalError({ open: true, data: t("strona_bledu.blad1") })

            }
            setLoading(false);

        });
    }

    function clickSortTable(column) {
        let directiontmp = dataSort.direction === 'ascending' ? 'descending' : 'ascending'
        if (directiontmp === "ascending") {
            setdataSort({ column: column, data: dataSort.data.sort(function (a, b) { return a[column].localeCompare(b[column]) }), direction: directiontmp })
        } else if (directiontmp === "descending") {
            setdataSort({ column: column, data: dataSort.data.sort(function (a, b) { return b[column].localeCompare(a[column]) }), direction: directiontmp })
        }

    }


    function getFVDuplicate(invoice_id, invoice_nr) {
        setloadinginvoiceduplicate(invoice_id)
        ConnectWS("POST", "/fv/getFVDuplicate/", authContext.token, { invoice_id: invoice_id, invoice_nr: invoice_nr }, function (response, logout) {

            if (logout === 1) {
                addToast(t("strona_bledu.wylogowanie"), { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);

            } else if (Number(response.status) === 200) {
                getData()
            } else {
                authContext.changeModalError({ open: true, data: t("strona_bledu.blad1") })
            }
            setloadinginvoiceduplicate(0)

        });
    }

    function getFVById(invoice_id, invoice_nr) {
        setloadinginvoice(invoice_id)
        ConnectWS("GET", "/fv/getFVById/" + invoice_id, authContext.token, {}, function (response, logout) {

            if (logout === 1) {
                addToast(t("strona_bledu.wylogowanie"), { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);

            } else if (Number(response.status) === 200) {

                try {
                    const file = new Blob([response.data], {
                        type: "application/pdf"
                    });
                    const url = window.URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = invoice_nr + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                } catch (e) {
                    console.log(e)
                    authContext.changeModalError({ open: true, data: t("strona_bledu.blad1") })
                }


            } else {
                authContext.changeModalError({ open: true, data: t("strona_bledu.blad1") })
            }
            setloadinginvoice(0)

        });
    }

    function getStatus(dt) {
        if (dt) {
            return <Label className='statusgreen'>{t("tablica.status_zaplacone")}</Label>
        } else {
            return <Label className='statusred'>{t("tablica.status_niezaplacone")}</Label>
        }
    }

    return (
        <div ref={ref} style={{ padding: 50, height: "100%" }}>
            <div style={{ float: 'left', fontSize: '10px', paddingLeft: 2, lineHeight: '10px', textAlign: 'right', color: '#d4d4d4', marginBottom: 2 }}>
                Ver: {packageJson.version} - <Icon name="mail" />{authContext && authContext.userName ? authContext.userName.email : ""}
            </div>
            <div style={{ float: 'right', fontSize: '10px', paddingRight: 0, lineHeight: '10px', textAlign: 'right', color: '#d4d4d4', marginBottom: 2 }}>
                <span><Icon name="time" /><MyTimerLive /> </span>
                <Icon name="calendar" />{getDateTimeE("DATE", new Date())}
                <div style={{ float: "right" }}><LanguageSelect /></div>
            </div>
       
            <div style={{ overflow: "auto", width: "100%", maxHeight: (height-250) < 300 ? 300 : (height-250), position: "relative", paddingBottom: 5 }} >
                <Table sortable unstackable >
                    <Table.Header className='headerfvtab'>
                        <Table.Row>
                            <TableHeaderCell width={2} textAlign='left' singleLine
                                sorted={dataSort.column === 'invoice_nr' ? dataSort.direction : null}
                                onClick={() => clickSortTable('invoice_nr')}
                            >{t("tablica.tabela_nr_faktury")}</TableHeaderCell>
                            <TableHeaderCell width={2} textAlign='center' singleLine
                                sorted={dataSort.column === 'invoice_dt' ? dataSort.direction : null}
                                onClick={() => clickSortTable('invoice_dt')}
                            >{t("tablica.tabela_dt_wystawienia")}</TableHeaderCell>
                            <TableHeaderCell width={2} textAlign='center' singleLine>{t("tablica.tabela_status")}</TableHeaderCell>
                            <TableHeaderCell width={2} textAlign='center' singleLine
                                sorted={dataSort.column === 'payment_deadline' ? dataSort.direction : null}
                                onClick={() => clickSortTable('payment_deadline')}
                            >{t("tablica.tabela_termin_platnosci")}</TableHeaderCell>
                            <TableHeaderCell width={2} textAlign='center' singleLine
                                sorted={dataSort.column === 'payment_deadline' ? dataSort.direction : null}
                                onClick={() => clickSortTable('payment_deadline')}
                            >{t("tablica.tabela_data_pobrania")}</TableHeaderCell>
                            <TableHeaderCell></TableHeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Header >
                        <Table.Row>
                            <TableHeaderCell colspan={6}>{t("tablica.tabela_niezaplacone")}</TableHeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isloading ? <Table.Cell colspan={6} textAlign='center' className='noInvoices'>
                            {loadingShow()}
                        </Table.Cell> : ""}
                        {dataSort && dataSort.data && dataSort.data.length > 0 ?
                            dataInfo.countFvNotPaid > 0 ?
                                dataSort.data.map((row, i) =>
                                    row.paid_dt === null ?
                                        <Table.Row key={i}>
                                            <Table.Cell textAlign='left' singleLine>
                                                {row.invoice_nr}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>
                                                {row.invoice_dt}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>
                                                {getStatus(row.paid_dt)}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>
                                                {row.payment_deadline}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>
                                                <Popup
                                                    trigger={<div>{row.downloaded_dt}</div>}
                                                    content={row.downloaded_email}
                                                    position='top center'

                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign='right' singleLine>
                                                {row.duplicate_fv === 1 || row.duplicateButton === 2 ? "" : <Button size='mini' loading={row.invoice_id === loadinginvoiceduplicate ? true : false} icon labelPosition='left' onClick={() => getFVDuplicate(row.invoice_id, row.invoice_nr)} disabled={row.duplicateButton === 0 ? false : true}><Icon name={row.duplicateButton === 0 ? 'copy' : 'check'} /> {row.duplicateButton === 0 ? t("tablica.przycisk_duplikat") : <> {t("tablica.przycisk_duplikat_wyslany")} </>}</Button>}
                                                <Button size='mini' loading={row.invoice_id === loadinginvoice ? true : false} disabled={row.invoice_id === loadinginvoice ? true : false} icon labelPosition='left' onClick={() => getFVById(row.invoice_id, row.invoice_nr)}><Icon name='download' color={row.duplicate_fv === 1 ? "blue" : ""} /> {row.duplicate_fv === 1 ? t("tablica.przycisk_pobierz_fakture_duplikat") : t("tablica.przycisk_pobierz_fakture")}</Button>
                                            </Table.Cell>
                                        </Table.Row> : ""
                                ) : <Table.Row >
                                    <Table.Cell colspan={6} textAlign='center' className='noInvoices'>
                                        <div style={{ margin: 50 }}> {t("tablica.brak_faktur_nieoplaconych")}<br />
                                            <span style={{ fontSize: 14, fontWeight: "normal" }}>{t("tablica.brak_faktur_nieoplaconych2")}</span>
                                        </div>
                                    </Table.Cell>
                                </Table.Row> : <Table.Row >
                                <Table.Cell colspan={6} textAlign='center' className='noInvoices'>
                                    <div style={{ margin: 50 }}> {t("tablica.brak_faktur_nieoplaconych")}<br />
                                        <span style={{ fontSize: 14, fontWeight: "normal" }}>{t("tablica.brak_faktur_nieoplaconych2")}</span>
                                    </div>
                                </Table.Cell>
                            </Table.Row>}

                    </Table.Body>
                    <Table.Header >
                        <Table.Row>
                            <TableHeaderCell colspan={6}>{t("tablica.tabela_zaplacone")}</TableHeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isloading ? <Table.Cell colspan={6} textAlign='center' className='noInvoices'>
                            {loadingShow()}
                        </Table.Cell> : ""}
                        {dataSort && dataSort.data && dataSort.data.length > 0 ?
                            dataInfo.countFvPaid > 0 ?
                                dataSort.data.map((row, i) =>
                                    row.paid_dt !== null ?
                                        <Table.Row key={i}>
                                            <Table.Cell textAlign='left' singleLine>
                                                {row.invoice_nr}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>
                                                {row.invoice_dt}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>
                                                {getStatus(row.paid_dt)}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>
                                                {row.payment_deadline}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>
                                                <Popup
                                                    trigger={<div>{row.downloaded_dt}</div>}
                                                    content={row.downloaded_email}
                                                    position='top center'

                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign='right' singleLine>
                                                <Button size='mini' loading={row.invoice_id === loadinginvoice ? true : false} disabled={row.invoice_id === loadinginvoice ? true : false} icon labelPosition='left' onClick={() => getFVById(row.invoice_id, row.invoice_nr)}><Icon name='download' color={row.duplicate_fv === 1 ? "blue" : ""} /> {row.duplicate_fv === 1 ? t("tablica.przycisk_pobierz_fakture_duplikat") : t("tablica.przycisk_pobierz_fakture")}</Button>
                                            </Table.Cell>
                                        </Table.Row> : ""
                                ) : <Table.Row >
                                    <Table.Cell colspan={6} textAlign='center' className='noInvoices'>
                                        <div style={{ margin: 50 }}> {t("tablica.brak_faktur_oplaconych")} </div>
                                    </Table.Cell>
                                </Table.Row> : <Table.Row >
                                <Table.Cell colspan={6} textAlign='center' className='noInvoices'>
                                    <div style={{ margin: 50 }}> {t("tablica.brak_faktur_oplaconych")} </div>
                                </Table.Cell>
                            </Table.Row>}
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default ViewDashboard;