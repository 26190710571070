import React, { useState, useEffect, useContext } from 'react';
import { Form, Segment, Button } from 'semantic-ui-react'
import styles from './Login.module.css';
import LogoElte from '../../assets/images/LogoElte.svg';
import useAuth from '../../hooks/useAuth';
import { withRouter } from 'react-router';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import AuthContext from '../../context/authContext';

import { useToasts } from 'react-toast-notifications';
import { SCezardecrypt, SCezarencrypt } from '../../myfunctions/myfunctions';
import { useLocation } from 'react-router-dom';
import LanguageSelect from '../../languageSelect';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../package.json';

function Login(props) {
    const { addToast } = useToasts();
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const [isloading, setLoading] = useState(false);
    const authContext = useContext(AuthContext);

    const [FormE, setFormE] = useState({ email: null, emailError: false, tokenCode: null, tokenCodeError: null,emailCnt:0,emailCntDt:"" });

    const [viewNr, setviewNr] = useState(1);
    const { hash } = useLocation();
    const { t } = useTranslation();

    const submit = e => {
       
        setLoading(true);
        ConnectWS("POST","/login", null, { email: FormE.email }, function (response, logout) {
            if (logout === 1) {
                setLoading(false);
          
                setFormE({ ...FormE, emailError: response.error })

            } else if (Number(response.status) === 200) {
                setLoading(false);
                
                if (response.data.status === 1 || response.data.status === 0) {
                    // przeszło poprawnie
                    setFormE({ ...FormE, emailCnt: response.data.emailCnt ? response.data.emailCnt : "?" , emailCntDt:response.data.emailCntDt ? response.data.emailCntDt : "?" })
                    addToast(t("strona_logowania.login_wiadomosc1"), { appearance: 'info' });
                    setviewNr(2)
                    window.sessionStorage.setItem('m', SCezarencrypt(FormE.email));
                    window.sessionStorage.setItem('t', true);
                }else if (response.data.status === 2) {
                    setFormE({ ...FormE, emailError: t("strona_bledu.login_blad_2") })
                }else if (response.data.status === 4) {
                    setFormE({ ...FormE, emailError: t("strona_bledu.login_blad_4") })
                }else if (response.data.status === 5) {
                    // nieznany adres email
                    setFormE({ ...FormE, emailError: t("strona_bledu.login_blad_5") })
                } else {
                    setFormE({ ...FormE, emailError: response.data.message })
                    
                }

               
            } else {
                setLoading(false);
               // addToast(t("strona_bledu.blad1"), { appearance: 'error' });
                authContext.changeModalError({ open: true, data: t("strona_bledu.blad1") })
            }




        });
    }

    const submitCode = e => {

        setLoading(true);
        setFormE({ ...FormE, tokenCodeError: null })
        ConnectWS("POST","/login/getToken", null, { email: FormE.email, passCode: FormE.tokenCode }, function (response, logout) {

            if (logout === 1) {
                setLoading(false);
                setFormE({ ...FormE, tokenCodeError: response.error })

            } else if (Number(response.status) === 200) {


                if (response.data.status === 1) {
                    // przeszło poprawnie
                    let userInfo = response.data
                    authContext.changeUserName(userInfo)
                    authContext.changeToken(response.data.token);
                    window.sessionStorage.setItem('userInfo', SCezarencrypt(JSON.stringify(userInfo)));
                    setAuth(true, response.data.token);
                }else if (response.data.status === 0) {
                    setFormE({ ...FormE, tokenCodeError: t("strona_bledu.token_blad_0") })
                }else if (response.data.status === 2) {
                    setFormE({ ...FormE, tokenCodeError: t("strona_bledu.token_blad_2")+" "+response.data.message })
                }

                
                setLoading(false);
            } else {
                setLoading(false);
                setAuth(false);
                addToast(t("strona_bledu.blad1"), { appearance: 'error' });
            }


        });




    }



    const checkUser = () => {

        let token = window.sessionStorage.getItem('token');

        let email = window.sessionStorage.getItem('m'); //setviewNr(2)

        if (email) {
            setFormE({ ...FormE, email: SCezardecrypt(email) })
        }

        let codePass = window.sessionStorage.getItem('t');
   

        if (codePass) {
            setviewNr(2)
        }

        let userInfo = window.sessionStorage.getItem('userInfo') ? JSON.parse(SCezardecrypt(window.sessionStorage.getItem('userInfo'))) : null;

        if (token) {
            // addToast('Zostałeś/aś zalogowana automatycznie', { appearance: 'info' });
           
            authContext.changeToken(token);
            if (userInfo) {
                authContext.changeUserName(userInfo)
            }
            setAuth(true, token);
        }
    }

    function getEmail(hash) {
        try {
            let em = hash.replaceAll('#email=', '')
            setFormE({ ...FormE, email: em })
        } catch (e) {

        }
    }

    useEffect(() => {

   
      
        if (hash.includes("email")) {
            getEmail(hash)
        }

        checkUser()
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, []);// eslint-disable-line react-hooks/exhaustive-deps



    return (
        <>

            <div className={styles.wrappergrid} >
                <div className={styles.wrappergridcontent}>


                    <Segment style={{ margin: 15, backgroundColor: "#FCFCFC",position:"relative" }}>
                    <div style={{ position: "absolute",right:10,top:10 }}><LanguageSelect /></div>

                        <div style={{ textAlign: "center", marginTop: 15, marginBottom: 20 }}>
                            <img src={LogoElte} alt="LogoElte" className={styles.imagelogo} />
                            <div className={"appname"} >{t("strona_logowania.nazwa_aplikacji")}</div>
                        </div>


                        {viewNr === 1 ?
                            <Form onSubmit={submit} autoComplete="off">
                                <label className={styles.labelEmail}>{t("strona_logowania.pole_email_nazwa")}</label>
                                <Form.Input
                                    fluid
                                    icon='mail'
                                    iconPosition='left'
                                    placeholder={t("strona_logowania.pole_email_text")}
                                    type='email'
                                    style={{ marginTop: 10 }}
                                    name='Email'
                                    title={t("strona_logowania.pole_email_text")}
                                    error={FormE.emailError ? { content: FormE.emailError } : false}
                                    value={FormE.email}
                                    onChange={(event, data) => setFormE({ ...FormE, email: data.value })}
                                    required
                                />
                                <div className={styles.infoEmail}>{t("strona_logowania.text1")}</div>



                                <Button size='large' color="orange" fluid disabled={isloading ? true : false} loading={isloading} >
                                    {t("strona_logowania.przycisk_gkd")}
                                </Button>



                            </Form>
                            :
                            <>
                                <Form onSubmit={submitCode} autoComplete="off">
                                    <label className={styles.labelEmail}>{t("strona_logowania.pole_kod_nazwa1")+FormE.emailCnt+t("strona_logowania.pole_kod_nazwa2")+FormE.emailCntDt}<span style={{fontSize:12,top:-4,position:"relative"}}>*</span></label>
                                    <Form.Input
                                        fluid

                                        placeholder={t("strona_logowania.pole_kod_text")}
                                        style={{ marginTop: 10 }}
                                        type='number'
                                        title={t("strona_logowania.pole_kod_text")}
                                        error={FormE.tokenCodeError ? { content: FormE.tokenCodeError } : false}
                                        value={FormE.tokenCode}
                                        onChange={(event, data) => setFormE({ ...FormE, tokenCode: data.value })}
                                        required
                                    />
                                    <div className={styles.infoEmail}>*{t("strona_logowania.pole_wymagane")}</div>



                                    <Button color="orange" fluid disabled={isloading ? true : false} loading={isloading} >
                                    {t("strona_logowania.przycisk_logowania")}
                                    </Button>



                                </Form>
                                <Button style={{ marginTop: 15 }} fluid onClick={() => {
                                    setviewNr(1)
                                    window.sessionStorage.removeItem('t');
                                    setFormE({ ...FormE, emailError: false, tokenCode: null, tokenCodeError: null })
                                }} >
                                   {t("strona_logowania.przycisk_powrot")}
                                </Button>
                            </>
                        }
                    <div style={{right:5,position:"absolute",fontSize:8,bottom:-3,color:"#b1b1b1"}}>{packageJson.version}</div>    
                    </Segment>

                </div>
            </div></>


    );
}



export default withRouter(Login);